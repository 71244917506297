'use client'

import { useState } from 'react'
import { confirmSignIn, signIn } from 'aws-amplify/auth'

import { Button, ZodForm } from '@/components'
import { LoginSchema, LoginType } from '@/utils/schemas'

import ChangeTemporaryPasswordModal from './ChangeTemporaryPasswordModal'

const loginFormFields = [
  {
    name: 'email',
    label: 'Email',
    config: {
      field: 'input' as const,
      type: 'text'
    }
  },
  {
    name: 'password',
    label: 'Password',
    config: {
      field: 'input' as const,
      type: 'password'
    }
  }
]

const LoginForm = () => {
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false)

  const login = async (data: LoginType) => {
    try {
      const res = await signIn({ username: data.email, password: data.password })

      if (res.isSignedIn) {
        window.location.replace('/')
      } else if (res.nextStep.signInStep === 'CONFIRM_SIGN_UP') {
        throw new Error('You must first verify your account before logging in.')
      } else if (res.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        setIsChangingPassword(true)
      } else {
        throw new Error('An error has occurred. Please try again')
      }
    } catch (error) {
      alert(error)
    }
  }

  const confirmPasswordChange = async (newPassword: string) => {
    try {
      await confirmSignIn({ challengeResponse: newPassword })
      setIsChangingPassword(false)
      window.location.replace('/')
    } catch (error) {
      alert(error)
    }
  }

  return (
    <>
      <ZodForm id="login-form" fields={loginFormFields} schema={LoginSchema} onSubmit={login} />
      <Button className="smet-primary-button" type="submit" form="login-form">
        Log in
      </Button>
      <ChangeTemporaryPasswordModal
        isOpen={isChangingPassword}
        handleChange={confirmPasswordChange}
        handleClose={() => setIsChangingPassword(false)}
      />
    </>
  )
}

export default LoginForm
